<script>
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import { computed } from 'vue'
import { cardCode } from '/~/utils/cards'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { formatNumber } from '/~/utils/format/numeric'
import { formatPhone } from '/~/utils/format/string'
import { ActivityStatus } from '/~/composables/activity/core/ActivityStatus'
import { useCms } from '/~/composables/cms'
import { useLocalization } from '/~/composables/localization'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'activity-details-order-print',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { ewalletLabels } = useCms()
    const {
      providerTitle,
      taxationLabel,
      taxationPercentage,
      isUserAddressEnabled,
    } = useProvider()
    const { translate } = useLocalization()

    const taxNumber = computed(() => props.content?.business?.taxNumber)

    const statusLabel = new ActivityStatus(
      (props.content.status ?? '').toLowerCase(),
      props.content.type
    )?.statusLabel

    return {
      ewalletLabels,
      taxNumber,
      providerTitle,
      formatDate,
      formatDollar,
      formatPhone,
      formatNumber,
      taxationLabel,
      taxationPercentage,
      isUserAddressEnabled,
      PaymentMethodType,
      translate,
      statusLabel,
    }
  },
  computed: {
    activity() {
      return this.content.activity || {}
    },
    status() {
      return this.content.status?.toLowerCase()
    },
    statusComplete() {
      return this.status === 'completed'
    },
    billingAddress() {
      const {
        firstName,
        lastName,
        streetAddress,
        postcode,
        suburb,
        state = '',
        email,
        mobile,
        companyName,
      } = this.content.address || {}

      return [
        [firstName, lastName].filter(Boolean).join(' '),
        companyName,
        [streetAddress, suburb, `${postcode},`, state.toUpperCase()]
          .filter(Boolean)
          .join(' '),
        email,
        formatPhone(mobile),
      ].filter(Boolean)
    },
    paymentMethods() {
      const paymentMethods =
        this.content.paymentMethods || this.activity.displayPaymentMethods || []

      return paymentMethods.map((method) => {
        let result

        if (method.type) {
          result = {
            ...method,
          }
        } else {
          result = {
            type: method,
            amount: 0,
          }
        }

        const issuingNetwork = getCardTypeByNumber(method.number)
        const brand = cardCode({ brand: issuingNetwork?.type })

        if (brand) {
          result.brand = `cards/${brand}`
        }

        const { number = '' } = result

        switch (result.type) {
          case this.PaymentMethodType.points:
            result.title = 'Points'
            break
          case this.PaymentMethodType.creditCard:
            result.title = brand || 'Credit Card'
            result.subtitle = number.replace(
              /(.{4})(.*)(.{4})/,
              '$1 XXXXXX X$3'
            )
            break
          case this.PaymentMethodType.bankAccount:
            result.title = 'Bank Account'
            result.subtitle = this.translate('bankAccount.details', {
              acc: `*** *** *${number.slice(-2)}`,
              bsb: result.bsb || result.prefix,
            })
            break
          case this.PaymentMethodType.eWallet:
            result.title = this.ewalletLabels.ewalletCash
            break
          case this.PaymentMethodType.couponCode:
            result.title = 'Coupon code'
            result.subtitle = number
            break
        }

        return result
      }) // .filter(i => i.amount > 0)
    },
    fees() {
      return this.content.fees?.items || []
    },
    totalFees() {
      return this.paymentFees + this.shippingFees
    },
    paymentFees() {
      return this.getFeesByType('Processing Fee')
    },
    paymentFeesBeforeGst() {
      return this.amountBeforeGst(this.paymentFees, this.taxationPercentage)
    },
    paymentFeesGst() {
      return this.getGst(this.paymentFees, this.taxationPercentage)
    },
    paymentFeesPercent() {
      return this.getFeesPercentageByType('Processing Fee')
    },
    showPaymentFees() {
      return this.paymentFees > 0
    },
    shippingFees() {
      return this.getFeesByType('Shipping Fee')
    },
    shippingFeesGst() {
      return this.getGst(this.shippingFees, this.taxationPercentage)
    },
    shippingFeesBeforeGst() {
      return this.amountBeforeGst(this.shippingFees, this.taxationPercentage)
    },
    shippingFeesPercent() {
      return ((this.shippingFees * 100) / this.total).toFixed(2)
    },
    showShippingFees() {
      return this.shippingFees > 0
    },
    pointsRedeemed() {
      return (this.activity.pointsTransactions ?? []).find(
        (transaction) => transaction.type === 'burn'
      )
    },
    pointsRedeemedValue() {
      return this.pointsRedeemed?.allocatedValue ?? 0
    },
    pointsMonetaryValueRedeemed() {
      if (this.paymentMethods.length) {
        const pointsPaymentMethod = this.paymentMethods.find(
          (method) => method.type === this.PaymentMethodType.points
        )

        if (pointsPaymentMethod) {
          return pointsPaymentMethod.finalAmount
        }
      }
      return ''
    },
    subtotal() {
      return this.content.subtotal ?? 0
    },
    total() {
      return this.content.total ?? 0
    },
    totalGst() {
      return (
        this.shippingFeesGst +
        this.paymentFeesGst +
        this.eStore.reduce((acc, e) => acc + this.calculateTax(e), 0)
      )
    },
    items() {
      return this.content.items ?? []
    },
    cards() {
      return this.items.filter(
        (i) => ['giftcard', 'dining', 'voucher'].includes(i.type) || !i.type
      )
    },
    eStore() {
      return this.items.filter((i) => i.type === 'estore')
    },
    cinemaBooking() {
      return this.content.cinema_booking || {}
    },
    ticketPrice() {
      return this.cinemaBooking.ticket_price
    },
    ticketsCount() {
      return this.cinemaBooking.total_selected_tickets
    },
  },
  methods: {
    getFeesByType(label) {
      const fees = this.fees.filter((i) => i.label === label)

      return fees.reduce((value, fee) => {
        return Number(fee?.amount ?? 0) + value
      }, 0)
    },
    getFeesPercentageByType(label) {
      return this.fees
        .filter((i) => i.label === label)
        .reduce((percentage, fee) => {
          return Number(fee?.percentageFee ?? 0) + percentage
        }, 0)
    },
    getGst(value, taxPercentage) {
      const valueExcludingTax = this.amountBeforeGst(value, taxPercentage)

      return value - valueExcludingTax
    },
    amountBeforeGst(value, taxPercentage) {
      return value / (1.0 + taxPercentage / 100)
    },
    calculateTax(item) {
      let result = 0
      const isEstore = item.type === 'estore'

      if (isEstore || item.priceGst > 0) {
        result = (item.priceGst - item.price) * item.quantity
      }

      return result > 0 ? result : '-'
    },
  },
}
</script>

<template>
  <div>
    <div class="space-y-[15px]">
      <div class="border-b pb-[15px] text-sm leading-7">
        <p>
          <strong>Date:</strong>
          {{ formatDate('daymonthyearlongtime', content.date) }}
        </p>
        <p>
          <strong>Order no.</strong>
          {{ content.number }}
        </p>
        <p>
          <strong class="mr-1">Status:</strong>
          <span :class="[statusLabel.color]">
            {{ statusLabel.text }}
          </span>
        </p>
      </div>

      <div class="flex justify-between">
        <div>
          <div v-if="isUserAddressEnabled && billingAddress.length">
            <strong>Billing:</strong>
            <div
              v-for="(item, index) of billingAddress"
              :key="`address-item-${index}`"
            >
              {{ item }}
            </div>
          </div>
        </div>

        <div v-if="taxNumber" data-testid="tax-registration">
          <strong>{{ taxationLabel }} Registration:</strong>
          {{ taxNumber }}
        </div>
      </div>
    </div>

    <table class="w-full">
      <tr class="border-b">
        <th class="w-2/6 py-[15px] pl-0 text-left">Item</th>
        <th class="w-1/6 py-[15px] text-left">Price</th>
        <th class="w-1/6 py-[15px] text-left">Quantity</th>
        <th class="w-1/6 py-[15px] text-left" data-testid="table-header-tax">
          {{ taxationLabel }}
        </th>
        <th class="w-1/6 py-[15px] text-right">Total</th>
      </tr>
      <tr v-for="item in cards" :key="item.id" class="border-b">
        <template v-if="content.isCinema">
          <td class="py-2.5 pr-2.5">Cinema Ticket</td>
          <td class="py-2.5 pr-2.5">
            {{ formatDollar(ticketPrice) }}
          </td>
          <td class="py-2.5 pr-2.5">
            {{ ticketsCount }}
          </td>
          <td data-testid="table-row-tax" class="py-2.5 pr-2.5">
            {{ taxationLabel }} FREE
          </td>
          <td class="py-2.5 pr-2.5 text-right">
            {{ formatDollar(content.subTotal) }}
          </td>
        </template>
        <template v-else>
          <td class="py-2.5 pr-2.5">
            {{ item.type === 'dining' ? item.name : item.retailerName }}
            {{ formatDollar(item.value) }}
            {{ item.physical ? 'Physical ' : 'e' }}Gift Card
          </td>
          <td class="py-2.5 pr-2.5">
            {{ formatDollar(item.price) }}
          </td>
          <td class="py-2.5 pr-2.5">
            {{ item.quantity }}
          </td>
          <td class="py-2.5 pr-2.5">
            {{ calculateTax(item) }}
          </td>
          <td class="py-2.5 text-right">
            {{ formatDollar(item.subTotal) }}
          </td>
        </template>
      </tr>
      <tr v-for="item in eStore" :key="item.id" class="border-b">
        <td class="py-2.5 pr-2.5">
          {{ item.name }}
        </td>
        <td class="py-2.5 pr-2.5">
          {{ formatDollar(item.price) }}
        </td>
        <td class="py-2.5 pr-2.5">
          {{ item.quantity }}
        </td>
        <td class="py-2.5 pr-2.5">
          {{ formatDollar(calculateTax(item)) }}
        </td>
        <td class="py-2.5 text-right">
          {{ formatDollar(item.priceGst * item.quantity) }}
        </td>
      </tr>
      <tr v-if="pointsRedeemedValue">
        <td class="py-2.5 pr-2.5">Points redeemed</td>
        <td class="py-2.5 pr-2.5" colspan="3">
          {{ formatNumber(pointsRedeemedValue) }}
        </td>
        <td class="py-2.5 text-right">
          {{ formatDollar(pointsMonetaryValueRedeemed) }}
        </td>
      </tr>
      <tr>
        <td class="pt-5 pr-2.5" />
        <td class="pt-5 pr-2.5" />
        <td class="pt-5 pr-2.5 font-bold" colspan="2">Sub total</td>
        <td class="pt-5 text-right">
          {{ formatDollar(subtotal) }}
        </td>
      </tr>
      <tr v-if="showShippingFees" class="border-b">
        <td class="py-2.5 pr-2.5">
          <strong>Shipping fees</strong>
        </td>
        <td class="py-2.5 pr-2.5">
          {{ formatDollar(shippingFeesBeforeGst) }}
        </td>
        <td class="py-2.5 pr-2.5">{{ shippingFeesPercent }}%</td>
        <td class="py-2.5 pr-2.5" data-testid="shipping-fees-gst">
          {{ formatDollar(shippingFeesGst) }}
        </td>
        <td class="py-2.5 text-right">
          {{ formatDollar(shippingFees) }}
        </td>
      </tr>
      <tr v-if="showPaymentFees" class="border-b">
        <td class="py-2.5 pr-2.5">
          <strong>Payment processing fees</strong>
        </td>
        <td class="py-2.5 pr-2.5">
          {{ formatDollar(paymentFeesBeforeGst) }}
        </td>
        <td class="py-2.5 pr-2.5" />
        <td class="py-2.5 pr-2.5" data-testid="payment-fees-gst">
          {{ formatDollar(paymentFeesGst) }}
        </td>
        <td class="py-2.5 text-right">
          {{ formatDollar(paymentFees) }}
        </td>
      </tr>
      <tr class="sub">
        <td class="py-2.5 pr-2.5" />
        <td class="py-2.5 pr-2.5" />
        <td class="py-2.5 pr-2.5 font-bold" colspan="2">Total amount paid</td>
        <td class="py-2.5 text-right text-2xl">
          <strong>{{ formatDollar(total) }}</strong>
        </td>
      </tr>
      <template v-if="paymentMethods.length">
        <tr class="sub">
          <td class="py-2.5" colspan="4">
            <strong>Payment methods</strong>
          </td>
          <td class="py-2.5 text-right" />
        </tr>
        <tr v-for="method of paymentMethods" :key="method.id" class="sub">
          <td class="py-2.5 pr-2.5" colspan="4">
            <div class="capitalize">
              {{ method.title }}
            </div>
            <div class="text-sm">
              {{ method.subtitle }}
            </div>
          </td>
          <td class="py-2.5 pl-5 text-right">
            {{ formatDollar(-method.finalAmount) }}
          </td>
        </tr>
      </template>
    </table>

    <table
      v-if="totalFees || totalGst"
      class="w-full"
      style="page-break-before: always"
    >
      <tr>
        <th class="w-2/6 py-[15px] pl-[15px] text-left" />
        <th class="w-1/6 py-[15px] pl-[15px] text-left" />
        <th class="w-1/6 py-[15px] pl-[15px] text-left" />
        <th class="w-1/6 py-[15px] pl-[15px] text-left" />
        <th class="w-1/6 py-[15px] pl-[15px] text-left" />
      </tr>
      <tr v-if="totalFees">
        <td class="py-[15px] pb-0 pl-[15px]" colspan="5">
          <p class="mt-6 text-left font-bold">
            Tax Invoice breakdown for {{ providerTitle }} services as calculated
            above
          </p>
        </td>
      </tr>
      <tr v-if="shippingFees">
        <td class="py-[15px] pb-0 pl-[15px]" colspan="4">Shipping Fee</td>
        <td class="py-[15px] pl-[15px]">
          {{ formatDollar(shippingFees) }}
        </td>
      </tr>
      <tr v-if="paymentFees">
        <td class="py-[15px] pb-0 pl-[15px]" colspan="4">
          Payment Processing Fee
        </td>
        <td>{{ formatDollar(paymentFees) }}</td>
      </tr>
      <tr v-if="totalFees">
        <td class="py-[15px] pb-0 pl-[15px]" colspan="3" />
        <td class="py-[15px] pl-[15px]">
          <strong>Total</strong>
        </td>
        <td class="py-[15px] pl-[15px] text-2xl">
          <strong>{{ formatDollar(totalFees) }}</strong>
        </td>
      </tr>
      <tr v-if="totalGst">
        <td class="py-[15px] pb-0 pl-[15px]" colspan="3" />
        <td class="py-[15px] pl-[15px]">
          <strong>{{ taxationLabel }} applicable</strong>
        </td>
        <td class="py-[15px] pl-[15px]" data-testid="total-fees-gst">
          {{ formatDollar(totalGst) }}
        </td>
      </tr>
    </table>
  </div>
</template>
